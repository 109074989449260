<template>
  <list-container :list="deliveryZones" @load="loadMoreDeliveryZones" @query="queryDeliveryZones">
    <template v-slot:items>
      <div v-if="drawItems">
        <div v-for="(deliveryZone, index) in deliveryZones.delivery_zones" :key="deliveryZone.delivery_zone_id" @click="update(deliveryZone.delivery_zone_id)">
          <list-item-container :title="deliveryZone.name" :printBorder="index < count">
            <template v-slot:content>
              <div class="list-item-title first-capital-letter">
                {{ deliveryZone.name }}
              </div>
              <div class="detail-content">
                <div>{{deliveryZone.cities.length}} ciudades y {{deliveryZone.shipment_types.length}} metodos de envio</div>
              </div>
              <div class="list-item-subtitle" v-if="deliveryZone.update_date">
                Actualizado el {{ dateFormat(deliveryZone.update_date, 'D MMM YYYY') }}
              </div>
              <div class="list-item-subtitle" v-else>
                Actualizado el {{ dateFormat(deliveryZone.creation_date, 'D MMM YYYY') }}
              </div>
            </template>
          </list-item-container>
        </div>
      </div>
    </template>
  </list-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import ListContainer from '@/components/List/ListContainer'
import ListItemContainer from '@/components/List/ListItemContainer'
import { dateFormat } from '@/shared/format'

export default {
  mixins: [navigationMixin],
  components: {
    ListContainer,
    ListItemContainer
  },
  computed: {
    ...mapState('delivery', ['deliveryZones']),
    drawItems () {
      return this.deliveryZones && this.deliveryZones.delivery_zones
    },
    count () {
      return this.deliveryZones.delivery_zones.length - 1
    }
  },
  methods: {
    dateFormat,
    ...mapActions('delivery', ['getDeliveryZones', 'getMoreDeliveryZones']),
    update (id) {
      this.goToRouteByName('update-delivery-zone', { id })
    },
    loadMoreDeliveryZones (url) {
      this.getMoreDeliveryZones(url)
    },
    queryDeliveryZones (search) {
      this.getDeliveryZones(search)
    }
  },
  mounted () {
    this.getDeliveryZones()
  }
}
</script>
<style scoped>
.detail-content {
  width: 100%;
  text-align: left;
  color: #9E9E9E;
  font-size: 0.938rem;
  display: inline-block;
}
.list-item-subtitle {
  margin-bottom: 0.625rem;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .list-item-title {
    padding-top: 0.125rem;
    font-size: 0.938rem;
    line-height: 1.125rem;
  }
}
</style>
